<div style="height: 40%;overflow-y: scroll;width:98%;margin:auto">
    <div *ngFor="let chatMessage of chatMessages;let i = index;" style="margin:5px;">
        <div *ngIf="chatMessage.role === 'user'" class="user_prompts">
            <mat-icon aria-hidden="false" aria-label="account" fontIcon="account_circle"></mat-icon><br>
            <span>{{chatMessage.content}}</span>
        </div>
        <mat-card *ngIf="chatMessage.role === 'assistant' && chatMessage.content">
            <mat-icon aria-hidden="false" aria-label="assistant" fontIcon="psychology"></mat-icon>
            <app-openai-chat-assistant-message *ngIf="i+1 === chatMessages.length"
                [chatMessage]="chatMessage.content"></app-openai-chat-assistant-message>
            <app-render-markdown [markdownText]="chatMessage.content"
                *ngIf="i+1 < chatMessages.length">{{chatMessage.content}}</app-render-markdown>
        </mat-card>
        <div *ngIf="chatMessage.role === 'custom_prompt'" class="custom_promptdiv"
            (click)="onCustomPromptClicked(chatMessage)" (keyup) = "onCustomPromptClicked(chatMessage)" tabindex="i">
            <p>{{chatMessage.content}}?</p>
        </div>
        <mat-card *ngIf="chatMessage.role === 'error_message'" style="margin:5px">
            <p style="font-size: larger;font-weight:300;color:orange;">{{chatMessage.content}}</p>
        </mat-card>
    </div>
    <mat-card *ngIf="fetching" style="margin: 5px;">
        <div *ngFor="let optimisticMessage of optimisticMessages" style="margin:10px">
            <span appTypingAnimation [typeSpeed]="optimisticMessage.typeSpeed" [condition]=true
                [startDelay]="optimisticMessage.startDelay" [text]="optimisticMessage.message"
                style="font-size: larger;font-weight: 300;"></span>
        </div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card>
    <div>
        <form>
            <mat-form-field appearance="outline" style="width:100%">
                <textarea matInput name="userMessage" type="text" placeholder="What can I help you do?..."
                    [(ngModel)]="userMessage" [maxlength]="searchMaxLength" style="resize: none;"
                    (keyup.enter)="sendMessage()">xx xx</textarea>
                <button matSuffix mat-icon-button type="button" (click)="sendMessage()">
                    <mat-icon>send</mat-icon>
                </button>
                <mat-hint>{{userMessage.length}}/{{searchMaxLength}}</mat-hint>
            </mat-form-field>
        </form>
    </div>
    <div>
    </div>
</div>